<!--
 * 我的藏品
-->

<template>
  <div>
    <div class="collectionBox" :class="!isApp ? 'appTop' : ''">
      <van-tabs
        @click="onChangeTab"
        :color="'#8EE4C8'"
        background="#1C172A"
        line-height="3"
        line-width="16"
        :title-active-color="'#fff'"
        class="gift-tab"
      >
        <van-tab title="全部" :name="0"></van-tab>
        <van-tab title="藏品" :name="1"></van-tab>
        <van-tab title="盲盒" :name="2"></van-tab>
        <van-tab title="特权" :name="3"></van-tab>
      </van-tabs>
      <div class="collectionContent">
        <div class="collectionList">
          <div class="collectionItem" v-for="(item, index) of collectionList" :key="index">
            <div class="itemBox" @click="showCard(item)">
              <div class="img">
                <div class="imageIconBox">
                  <img
                    class="imageIcon"
                    :class="
                      item.items_num > 1 && (item.type == 1 || item.type == 2)
                        ? 'imgMore'
                        : item.items[0].open_status == 0
                        ? 'imgMh'
                        : ''
                    "
                    :src="item.image_icon"
                    alt=""
                  />
                  <!--  -->
                  <div class="moveing" v-if="item.trans_num">
                    转赠中{{ item.trans_num !== item.items_num ? 'x' + item.trans_num : '' }}
                  </div>
                  <div class="learning" v-if="item.regular_num">
                    修炼中{{ item.regular_num !== item.items_num ? 'x' + item.regular_num : '' }}
                  </div>
                  <div class="wishing" v-if="item.wishing_num">
                    许愿中{{ item.wishing_num !== item.items_num ? 'x' + item.wishing_num : '' }}
                  </div>
                  <img
                    class="levelIcon"
                    v-if="item.items[0].items_type != 2"
                    :src="levelsChange(item.items[0].levels)"
                  />
                </div>
              </div>
              <div class="name">
                <p>{{ item.items[0].display_name }}</p>
                <p class="card-name">
                  {{ item.items[0].items_name }}
                  {{ 'x ' + item.items_num }}
                </p>
              </div>

              <!-- 之前的多选作废（有了新的转赠页面） -->
              <!-- <van-checkbox
              v-if="
                (diffTime(item.transfer_lock_time) < 0 || item.tranasction_type === 2) &&
                showCheckStatus &&
                item.status != 2
              "
              class="checkbox"
              v-model="item.checked"
              checked-color="#1C172A"
              @click.stop
            ></van-checkbox>

            <div
              v-if="
                showCheckStatus &&
                item.tranasction_type === 1 &&
                diffTime(item.transfer_lock_time) > 0
              "
              class="disSend"
            >
              <div>
                <div class="desc">购买{{ item.expire_days }}天后才可转赠</div>
                <van-count-down
                  class="time"
                  :time="diffTime(item.transfer_lock_time)"
                  format="DD 天 HH 小时后可转赠 "
                />
              </div>
            </div> -->
            </div>
          </div>
        </div>

        <div
          class="btn-box"
          style="margin-bottom: 60px"
          :style="{ 'justify-content': tab_type == 3 ? 'space-around' : '' }"
          v-if="!showCheckStatus && collectionList.length"
        >
          <div class="sendBtn" v-if="diffHours() < 72">
            <van-count-down
              class="countDown"
              millisecond
              :format="
                diffReRegisterTime() < 24 * 60 * 60 * 1000
                  ? 'HH小时mm分钟ss秒后可转赠'
                  : 'DD天HH小时后可转赠'
              "
              :time="diffReRegisterTime()"
              @finish="finistTime"
            />
          </div>
          <!-- <div class="sendBtn" v-else @click="showCheckFn">转赠</div> -->

          <div class="sendBtn" v-if="tab_type != 3" @click="showfenFn">分解</div>
          <div class="sendBtn learnBtn" @click="toLearning">
            闭关修炼
            <div class="dot" v-if="is_regular"></div>
          </div>
        </div>

        <div v-if="showCheckStatus && collectionList.length" class="sendBtnObj">
          <div @click="showCheckStatus = false" class="cancel">取消</div>
          <!-- <div @click="isShowInfo">转赠</div> -->
        </div>

        <!-- 展示图片弹窗 -->
        <van-popup v-model:show="cardShow" class="cardPopupBox">
          <div class="cardPopup">
            <div class="id">编号：{{ cardInfo.token_id + '-' + cardInfo.serial_num }}</div>
            <div class="name">{{ cardInfo.items_name }}</div>
            <div style="position: relative">
              <img class="img" :src="cardInfo.image_icon" alt="" />
              <img class="popLevelIcon" :src="levelsChange(cardInfo.levels)" alt="" srcset="" />
            </div>
            <div>{{ cardInfo.sub_name }}</div>
            <van-icon class="closeIcon" @click="cardShow = false" name="close" />
          </div>
        </van-popup>

        <van-popup v-model:show="infoShow">
          <div class="infoPopup">
            <div v-if="step == 1">
              <div class="title">输入转赠人手机号</div>
              <van-field
                class="input"
                :border="false"
                v-model="mobile"
                placeholder="请输入手机号"
              />
              <div class="btns">
                <div @click="submitPhone">提交</div>
              </div>
            </div>

            <div v-if="step == 2">
              <div class="title">验证实名信息</div>
              <van-field
                class="input"
                :border="false"
                v-model="sendUserInfo.id_name"
                placeholder="请输入姓名"
              />
              <div class="btns">
                <div @click="step = 1">上一步</div>
                <div @click="confirmSend">确认</div>
              </div>
            </div>
          </div>
        </van-popup>
      </div>
      <div v-if="isLoading" class="dataNullBox">
        <div>
          <img
            src="./../imgs/loading/loading.gif"
            style="width: 2rem; height: 2rem"
            alt=""
            srcset=""
          />
        </div>
        <div class="text">数据加载中……</div>
      </div>
      <div v-else-if="isLoadError" class="dataNullBox">
        <div>
          <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text" @click="getList">数据加载失败，请检查网络设置后刷新页面</div>
      </div>
      <div v-else-if="!collectionList.length" class="dataNullBox">
        <div>
          <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">暂无数据</div>
      </div>
      <!-- 特权弹窗 -->
      <van-popup class="vantPop" v-model:show="showDescription">
        <div class="failPopup">
          <div class="content">
            <div class="title">{{ items_name }}卡使用说明</div>
            <div v-html="description"></div>
          </div>
          <div class="btn">
            <div @click.stop="showDescription = false">知道了</div>
          </div>
        </div>
      </van-popup>

      <OpenTheBox
        v-if="showOpenBox"
        @close="closeBox"
        :itemsData="itemsData"
        :server_time="server_time"
        :id="boxId"
        :trans_num="trans_num"
        :notOrder="true"
      />

      <!-- 引导萌层 -->
      <div
        class="introduceModel"
        v-if="introduceFlag && collectionList.length && false"
        @click="closeModel"
      >
        <div
          class="btn-box"
          :style="{ 'justify-content': tab_type == 3 ? 'center' : 'space-around' }"
          v-if="!showCheckStatus && collectionList.length"
        >
          <div class="virtualBtn"></div>
          <div class="virtualBtn"></div>
          <div class="sendBtn introduceBtn" @click.stop="toLearning">
            闭关修炼
            <div class="tipBox">
              <div class="tip">
                <div class="inner"></div>

                <div>
                  <div>闭关修炼得积分</div>
                  <div class="boldText">全新上线！</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {
  getusercollections,
  contributeCertification,
  makeCollection,
  getusercollectionsfold
} from '@/service/user';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
import OpenTheBox from '@/components/OpenTheBox.vue';
import { useStore } from 'vuex';
import { localStorageUtil, nftUtils } from '@/utils';

export default {
  name: 'Collections',
  components: {
    OpenTheBox
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const from = route.query.from;
    console.log(window.history);
    if ('sand' === from && localStorage.getItem('sandpay_mobile')) {
      //如果来自衫德支付认证成功
      router.push({ path: '/', query: { from: 'sand' } });
    }
    const toastFn = inject('$toast');
    const state = reactive({
      itemsData: [],
      trans_num: 0,
      list: [
        { checked: true, statu: 1 },
        { checked: false, statu: 2 },
        { checked: false },
        { checked: false },
        { checked: false }
      ],
      checked: false,
      cardShow: false,
      cardInfo: null,
      infoShow: false,
      step: 1,
      mobile: null, // 转赠前实名验证手机号
      collectionList: [], // 我的藏品列表字段
      showCheckStatus: false,
      sendUserInfo: null, // 转赠验证人信息
      items_id_list: [],
      isApp: nftUtils.isInApp(),
      showOpenBox: false, //显示开盲盒弹窗
      boxId: null,
      tab_type: 0,
      description: '',
      items_name: '',
      showDescription: false,
      first: true,
      introduceFlag: false,
      is_regular: false,
      isLoading: false,
      isLoadError: false,
      // server_time: moment(),
      server_time: null,
      created_time: null
    });

    const onChangeTab = name => {
      state.tab_type = name;
      state.lastid = 0;
      state.collectionList = [];
      getList();
    };

    const modelWhether = () => {
      console.log('localStorage.getItem(', localStorage.getItem('modelFlag'));
      if (!localStorage.getItem('modelFlag')) {
        state.introduceFlag = true;
      } else {
        state.introduceFlag = false;
      }
    };

    const closeModel = () => {
      state.introduceFlag = false;
      localStorage.setItem('modelFlag', true);
    };

    // 获取列表
    const getList = () => {
      state.isLoading = true;
      state.isLoadError = false;
      getusercollectionsfold({ tab_type: state.tab_type })
        .then(res => {
          console.log(res);
          state.collectionList = res.data.items;

          state.is_regular = res.data.is_regular;
          state.collectionList.forEach(item => {
            item.checked = false;
          });
          state.server_time = res.data.server_time;
          state.isLoading = false;
          state.isLoadError = false;
          console.log('state.collectionList', state.collectionList);
        })
        .catch(err => {
          state.isLoading = false;
          state.isLoadError = true;
        });
      //是否为第一次请求，是第一次回到顶部
      if (state.first) {
        state.first = false;
        setTimeout(() => {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, 200);
      }
    };

    // 验证手机号码
    const submitPhone = () => {
      if (state.mobile) {
        nftUtils.setcnzzRecordByBtn('实名认证验证手机号');
        contributeCertification({
          mobile: state.mobile
        }).then(res => {
          console.log(res);
          if (res.status == 0) {
            state.sendUserInfo = res.data;
            state.step = 2;
          } else {
            // state.infoShow = false
            toastFn({ message: res.message });
            return false;
          }
        });
      } else {
        toastFn({ message: '请输入手机号' });
      }
    };

    const showfenFn = () => {
      router.push({ path: '/CollectionsMeth' });
    };

    const showCheckFn = () => {
      router.push({ path: '/collectionSend' });
      // state.showCheckStatus = true;
      // state.collectionList.forEach(item => {
      //   item.checked = false;
      // });
    };

    // 判断是否有选中需要转赠的藏品
    const isShowInfo = () => {
      state.items_id_list = [];
      for (let i in state.collectionList) {
        if (state.collectionList[i].checked) {
          state.items_id_list.push(state.collectionList[i].id);
        }
      }
      console.log(state.items_id_list);
      if (state.items_id_list.length) {
        state.mobile = '';
        state.infoShow = true;
      } else {
        toastFn({ message: '请选择转赠藏品' });
      }
    };

    // 确定转赠
    const confirmSend = () => {
      nftUtils.setcnzzRecordByBtn('实名认证完确定转赠');
      makeCollection({
        id_list: state.items_id_list,
        to_user_id: state.sendUserInfo.user_id
      }).then(res => {
        console.log(res);
        state.step = 1;
        state.infoShow = false;
        state.sendUserInfo = null;
        state.showCheckStatus = false;
        if (res.status == 0) {
          state.lastid = 0;
          state.collectionList = [];
          getList();
          toastFn({ message: '已提交转赠，待对方接收' });
        } else {
          toastFn({ message: res.message });
          return false;
        }
      });
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(state.server_time, 'millisecond');
    };

    // 查看大图
    const showCard = item => {
      if (item.type == 1 || item.type == 2) {
        if (item.trans_num < item.items_num) {
          item.items.sort((a, b) => a.status - b.status);
        }

        state.itemsData = item.items;
        state.server_time = item.server_time;
        state.trans_num = item.trans_num;
        state.showOpenBox = true;
      } else {
        state.description = item.items[0].description;
        state.items_name = item.items_name;
        state.showDescription = true;
      }

      // if (item.items_type == 1) {
      //   // if (item.status == 2 || state.showCheckStatus) {
      //   //   return;
      //   // }
      //   state.itemsData = item.items;
      //   return;
      //   //items_type藏品类型：1 普通藏品，2 特权
      //   if (item.open_status == 1) {
      //     // state.cardInfo = item;
      //     // state.cardShow = true;
      //     router.push({
      //       path: '/collectionsDetail',
      //       query: { id: item.id }
      //     });
      //   } else {
      //     //开启盲盒
      //     state.boxId = item.id;
      //     state.showOpenBox = true;
      //   }
      // } else {
      //   state.description = item.description;
      //   state.items_name = item.items_name;
      //   state.showDescription = true;
      // }
    };
    //关闭开启盲盒弹窗
    const closeBox = type => {
      console.log(type);
      state.showOpenBox = false;
      if (type) {
        state.lastid = 0;
        state.collectionList = [];
        getList();
      }
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    const toLearning = () => {
      router.push({ path: '/Learning/retreat' });
    };

    // 对比时间是否超过多少小时
    const diffHours = () => {
      return moment(state.server_time).diff(
        moment(Number(moment(state.created_time).format('x'))),
        'hours'
      );
    };

    // 计算与注册时间时间差
    const diffReRegisterTime = time => {
      return moment(
        moment(moment(state.created_time).add(3, 'd').format('YYYY-MM-DD HH:mm:ss'))
      ).diff(state.server_time, 'millisecond');
    };

    const countTime = () => {
      toastFn({ message: '新用户注册后3天后才可转赠藏品', duration: 3000 });
    };

    const finistTime = async () => {
      await store.dispatch('refreshLoginInfo');
      state.created_time = store.state.loginInfo.created_time;
      state.server_time = Number(localStorageUtil.get('server_time'));
    };

    onMounted(async () => {
      if (nftUtils.isLogin()) {
        await store.dispatch('refreshLoginInfo');
        state.created_time = store.state.loginInfo.created_time;
        // state.created_time = '2022-09-20T08:10:01.000Z';
        state.server_time = Number(localStorageUtil.get('server_time'));
        console.log(
          'state.server_time',
          moment(state.created_time)
            .add(+3, 'd')
            .format('YYYY-MM-DD HH:mm:ss')
        );
      }
      getList();

      modelWhether();
    });

    return {
      ...toRefs(state),
      submitPhone,
      getList,
      isShowInfo,
      confirmSend,
      diffTime,
      showCard,
      levelsChange,
      showCheckFn,
      closeBox,
      showfenFn,
      onChangeTab,
      noopenImg: require('../imgs/comm/Slice34.png'),
      closeModel,
      toLearning,
      diffHours,
      diffReRegisterTime,
      countTime,
      finistTime
    };
  }
};
</script>
<style lang="scss">
.collectionBox {
  .van-tab--active {
    font-size: 16px;
  }
}
</style>
<style lang="scss" scoped>
@import '../styles/mixin.scss';
.dot {
  width: 6px;
  height: 6px;
  background: #e63f3f;
  opacity: 1;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  top: 7px;
}
.countDown {
  display: inline-block;
}
.introduceModel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1500;

  .introduceBtn {
    position: relative;
    bottom: 55px;
    left: 5px;
    .tipBox {
      position: absolute;
      bottom: 58px;
      right: 0;
    }
  }

  .tip {
    position: relative;
    width: 165px;
    background: #000000;
    height: 51px;
    border: 0px;
    border-radius: 4px;
    border: 1px solid #ffe39a;
    color: white;
    font-size: 14px;
    font-weight: 400;
    color: #ffe7a8;
    line-height: 24px;
    text-align: center;

    // display: none;
    .inner {
      background: #000000;
      width: 10px;
      height: 10px;
      border: 1px solid #ffe39a;
      position: absolute;
      left: 107px;
      bottom: -5px;
      transform: rotate(45deg);
      border-left: 0px;
      border-top: 0px;

      // background-color: #37db90;
    }
  }

  .boldText {
    font-weight: bold;
  }
}

.failPopup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
.gift-tab {
  font-size: 14px;
  margin-bottom: 6px;
  width: 65%;
}
.btn-box {
  display: flex;
  position: fixed;
  bottom: 44px;
  padding: 0 16px;
  // justify-content: space-between;
  justify-content: space-around;
  width: 100%;
  box-sizing: border-box;

  // left: 50%;
  // transform: translateX(-50%);
  .sendBtn {
    position: relative;
    width: 106px;
    height: 38px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 16px;
    font-weight: 500;
    color: #1c172a;
    line-height: 38px;
    text-align: center;
  }
  .virtualBtn {
    width: 106px;
    height: 38px;
  }
}
.moveing {
  position: absolute;
  top: 0px;
  transform: translateY(-50%);
  left: 6px;
  padding: 0 4px;
  line-height: 18px;
  background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
  border-radius: 4px 4px 4px 4px;
  font-size: 10px;
  font-weight: 500;
  color: #1c172a;
}

.learning {
  position: absolute;
  top: 16px;
  left: 6px;
  padding: 0 4px;
  line-height: 18px;
  background: linear-gradient(132deg, #fff0bc 0%, #ffd057 100%);
  border-radius: 4px 4px 4px 4px;
  font-size: 10px;
  font-weight: 500;
  color: #1c172a;
}
.wishing {
  position: absolute;
  top: 40px;
  left: 6px;
  padding: 0 4px;
  line-height: 18px;
  background: linear-gradient(132deg, #ff93ad 0%, #d96fff 100%);
  border-radius: 4px 4px 4px 4px;
  font-size: 10px;
  font-weight: 500;
  color: #1c172a;
}
.collectionBox {
  // padding-top: 30px;
}
.appTop {
  padding-top: 70px;
}
.card-name {
  height: 19px;
  opacity: 0.7;
}

.collectionContent {
  padding-bottom: 150px;
  padding-top: 6px;

  .collectionList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;

    .collectionItem {
      text-align: center;
      width: 50%;
      margin-top: 12px;

      .itemBox {
        position: relative;
        margin: 0 5px;
        background: #494359;
        border-radius: 4px 4px 4px 4px;

        .img {
          padding: 14px 0 8px 0;
          margin: 0 auto;

          .imageIconBox {
            position: relative;
          }

          .imageIcon {
            width: 102px;
            height: 136px;
            // object-fit: cover;
          }
          .imgMore {
            width: 136px;
            height: 136px;
          }

          .imgMh {
            width: 124px;
            height: 136px;
          }

          .levelIcon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
            width: 43px;
            height: auto;
          }
        }

        .name {
          color: #ffffff;
          font-size: 12px;
          font-weight: 400;
          padding-bottom: 10px;

          p {
            margin: 0;
          }

          :first-child {
            font-size: 14px;
            line-height: 26px;
            font-weight: 500;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
          }
        }

        .checkbox {
          position: absolute;
          top: 0;
          right: 0;
          padding: 10px;
        }

        .disSend {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgba(28, 23, 42, 0.6);
          border-radius: 4px 4px 4px 4px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;

          .desc {
            font-size: 14px;
            font-weight: 600;
            padding: 10px 0;
          }
          .time {
            color: white;
            font-size: 12px;
          }
        }
      }
    }
  }

  .sendBtnObj {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: fixed;
    bottom: 44px;
    left: 50%;
    transform: translateX(-50%);

    div {
      width: 163px;
      height: 44px;
      border-radius: 4px 4px 4px 4px;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: 500;
      color: white;
      line-height: 44px;
      text-align: center;
    }
    .cancel {
      border: 1px solid rgba(255, 255, 255, 0.5);
    }

    :first-child {
      background: #1c172a;
    }

    :last-child {
      color: #1c172a;
      background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    }
  }

  .cardPopup {
    position: relative;
    width: 300px;
    background: #494359;
    border-radius: 8px 8px 8px 8px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 19px;

    .id {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      padding: 10px;
    }

    .name {
      font-size: 20px;
      font-weight: 600;
    }

    .popLevelIcon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 40px;
      width: 54px;
      height: auto;
    }

    .img {
      width: 184px;
      height: auto;
      border-radius: 4px 4px 4px 4px;
      object-fit: cover;
      padding: 12px 0;
    }

    .closeIcon {
      font-size: 36px;
      position: absolute;
      bottom: -80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .infoPopup {
    width: 303px;
    background: #494359;
    border-radius: 8px 8px 8px 8px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    padding: 20px;

    ::v-deep .van-field__control {
      color: #fff;
    }

    .input {
      background: #1c172a;
      border-radius: 4px 4px 4px 4px;
      color: white;
      margin: 20px 0;
    }

    .btns {
      display: flex;
      justify-content: space-around;

      div {
        width: 116px;
        line-height: 36px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        border-radius: 4px 4px 4px 4px;
      }
    }
  }
}

.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
.collectionContent {
  .van-popup {
    background: transparent !important;
  }
  .cardPopupBox {
    top: 58%;
    height: 100%;
  }
}
</style>
